import { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import RouteContext from '@/components/context/routerContext'
import { useRouter } from 'next/router'
import { CustomerService } from '@/lib/api/customer'

const usePushPageView = (eventData) => {
  const customer = useSelector((state) => state.userReducer)
  const PreviousUrlSet = RouteContext && useContext(RouteContext)?.PreviousUrlSet
  const previousUrl = RouteContext && useContext(RouteContext)?.previousUrl
  const { asPath } = useRouter()

  const getLocation = function (href) {
    const l = document.createElement('a')
    l.href = href
    return l
  }

  const event = (intervalId) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      const ifOnetrustLoaded = dataLayer.find((item) => item.event == 'OneTrustLoaded')
      // const ifOptanonLoaded = dataLayer.find(item => item.event == 'OptanonLoaded')
      // const ifOneTrustGroupsUpdated = dataLayer.find(item => item.event == 'OneTrustGroupsUpdated')
      if (ifOnetrustLoaded) {
        let current_url =
          process.env.NEXT_PUBLIC_BASE_URL.slice(0, 10) +
          (process.env.NEXT_PUBLIC_BASE_URL + asPath).slice(10).replace('//', '/')
        const refUrl = getLocation(
          previousUrl?.length > 0
            ? previousUrl[previousUrl?.length - 1]
            : window.frames.top.document.referrer
        )
        CustomerService.getCustomerDetails({}).then(async (response) => {
          if (response && response.id) {
            CustomerService.getCustomerGroup(response?.customer_group_id).then((res) => {
              CustomerService.getCustomerFlags().then((flag) => {
                let ifVirtualPageView = dataLayer.find((item) => item.event == 'page_view')
                dataLayer.push({
                  event: ifVirtualPageView ? 'virtual_page_view' : 'page_view',
                  page_location: current_url,
                  page_referrer:
                    previousUrl?.length > 0
                      ? previousUrl[previousUrl?.length - 1]
                      : window.frames.top.document.referrer,
                  user_id: response?.id,
                  traffic_type: refUrl?.hostname?.includes('sitka') ? 'internal' : 'external',
                  logged_status: true,
                  area_of_interest: '',
                  member_type: flag?.data?.[0]?.pro_user ? 'Pro' : 'Consumer',
                  member_subtype: res?.name,
                  ...eventData,
                  page_title:
                    document.getElementsByTagName('title').length > 0
                      ? document.getElementsByTagName('title')[0].innerHTML
                      : eventData?.page_title,
                  // dnsFlag: flag?.data?.[0]?.dns_flag || false,
                })
                clearInterval(intervalId)
              })
            })
          } else {
            let ifVirtualPageView = dataLayer.find((item) => item.event == 'page_view')
            dataLayer.push({
              event: ifVirtualPageView ? 'virtual_page_view' : 'page_view',
              page_location: current_url,
              page_referrer:
                previousUrl?.length > 0
                  ? previousUrl[previousUrl?.length - 1]
                  : window.frames.top.document.referrer,
              traffic_type: refUrl?.hostname?.includes('sitka') ? 'internal' : 'external',
              logged_status: false,
              area_of_interest: '',
              member_type: 'Consumer',
              member_subtype: '',
              ...eventData,
              page_title:
                document.getElementsByTagName('title').length > 0
                  ? document.getElementsByTagName('title')[0].innerHTML
                  : eventData?.page_title,
              // dnsFlag: false,
            })
            clearInterval(intervalId)
          }
        })
        PreviousUrlSet && PreviousUrlSet(current_url)
      }
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const e = document.getElementById('iframe-pixel')
      e &&
        e.contentWindow.postMessage(
          {
            action: 'isPro',
            logged_status: customer?.isAuthenticated,
            proStatus: customer?.promoOrderUser?.isProAppUser ? true : false,
            customerGroupValue: customer?.customerGroup?.name || '',
          },
          '*'
        )
    }

    const intervalId = setInterval(() => {
      event(intervalId)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [asPath])
}

export default usePushPageView

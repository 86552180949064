import dynamic from 'next/dynamic'
import { getPageData } from '@/lib/contentful/content'
import usePushPageView from '@/hooks/Events/pageViewHooks'
import { errorEvent } from '@/lib/ga-events'
import Head from 'next/head'

const PAGE_SLUG = 'homepage'

const Modules = dynamic(() => import('@/components/Modules/Modules'))
const ModuleScriptHead = dynamic(
  () => import('@/components/ModuleScriptHead/ModuleScriptHead').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)

const Layout = dynamic(() => import('@/components/Layout/Layout'))
const Error = dynamic(() => import('next/error'))

const Index = ({ page, preview }) => {
  usePushPageView({
    page_type: 'HomePage',
    page_sub_type: 'HomePage',
    page_title: page?.title ? page?.title : '',
  })
  if (page == null) {
    errorEvent('404', 'page not found')
    return <Error statusCode="404" />
  }

  let { modules = null, layoutData, seo } = page
  let newSeo = seo?.additionalMetaTags ? seo : {}

  return (
    <>
      <Head>
        {process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod' && (
          <meta
            name="google-site-verification"
            content="PWvbwo5OlQXNXBBdoSvn2lxlUTqvqzU9pvcEFL6CJJw"
          />
        )}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
                {
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  "name": "SITKA Gear",
                  "url": "${process.env.NEXT_PUBLIC_BASE_URL}",
                  "logo": "${process.env.NEXT_PUBLIC_BASE_URL}/images/sitka-logo-schema.svg",
                  "phone": "8777485247",
                  "sameAs": [
                    "https://www.facebook.com/sitkagear/",
                    "https://www.instagram.com/sitkagear/",
                    "https://www.youtube.com/SITKAGear"
                  ]
                }
              `,
          }}
        ></script>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
                {
                  "@context": "https://schema.org/",
                  "@type": "WebSite",
                  "name": "SITKA Gear",
                  "url": "${process.env.NEXT_PUBLIC_BASE_URL}",
                  "potentialAction": {
                    "@type": "SearchAction",
                    "target": "${process.env.NEXT_PUBLIC_BASE_URL}/search?q={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                }
              `,
          }}
        ></script>
      </Head>
      <Layout layoutData={layoutData} preview={preview} seo={newSeo}>
        <Modules modules={modules} preview={preview} />
        {page?.headInsert && <ModuleScriptHead headInsert={page?.headInsert} id={page?.slug} />}
      </Layout>
    </>
  )
}

export default Index

export const getStaticProps = async ({ locale, preview }) => {
  const page = await getPageData(PAGE_SLUG, 'page', preview)
  // console.log('page--', page)
  return {
    props: {
      page,
      preview: preview ? preview : null,
    },
  }
}
